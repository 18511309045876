import React from 'react';
import { Button } from 'antd-mobile';
import styled from 'styled-components';

function Error() {
  return (
    <Styled>
      <h2>404 页面出错</h2>
      <div className="error-boundary-image" />
      <div className="error-boundary-text">
        <p>恭喜你! 中奖了!</p>
        <p>这个隐藏的出错页居然被你发现啦!</p>
        <p>加下面微信, 立刻领取"神马探索大师"红包吧.</p>
      </div>
      <div className="error-boundary-barcode" />
      <div className="error-boundary-barcode-description">
        <p>长按保存图片 微信扫一扫</p>
      </div>
      <a href="/">
        <Button type="primary">返回首页</Button>
      </a>
      <style jsx>{``}</style>
    </Styled>
  );
}

const Styled = styled.div`
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .error-boundary-image {
    width: 180px;
    height: 20px;
  }
  .error-boundary-text,
  .error-boundary-barcode-description {
    color: #b2b2b2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .error-boundary-barcode {
    width: 200px;
    height: 200px;
    background: no-repeat url('../public/static/CS_wechat.JPG') 50%;
    background-size: contain;
  }
  .am-button-primary {
    width: 200px;
  }
`;

export default Error;
